import React, { useState } from 'react';

const IconsRow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      title: "A passion for quality, a dedication to performance",
      para: "Enduring beauty, from a family-run business committed to British craftsmanship and visual distinction.",
      image: "assets/Homepage/MDE176A.webp",
    },
    {
      title: "Unmatched realism, unrivalled excellence",
      para: "Thanks to its unique polymer resin construction, our composite decking and cladding does not deteriorate like natural wood and cannot be matched in durability or realism by other composite decking or cladding brands.",
      image: "assets/Homepage/MDE126D_Enhanced Grain SB_Smoked Oak_Hero 1.webp",
    },
    {
      title: "Created using recycled materials and absolutely no timber",
      para: "The unique Lastane surface provides our cladding with exceptional weather-resistance, and our decking boards superb slip-resistance, even when wet. Every board is produced using recycled minerals whilst containing no natural timber.",
      image: "assets/Homepage/MDE360D.webp",
    }
  ];

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    
    <div className="w-full bg-primary max-w-full">
      <div className="py-20">
        <h2 className="mb-12 uppercase text-green-dark text-4xl font-F37-light flex justify-center">
          Why Millboard?
        </h2>
        <div className="relative">
          {/* Carousel Inner */}
          <div className="flex overflow-hidden relative">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`transition-transform duration-500 ease-in-out transform ${index === currentSlide ? 'translate-x-0' : 'translate-x-full'} w-full flex-shrink-0`}
                style={{
                  transform: `translateX(${(index - currentSlide) * 100}%)`,
                }}
              >
                <div className="flex flex-wrap items-center">
                  <div className="w-full lg:w-2/3 flex justify-center lg:justify-start">
                    <img src={slide.image} alt={slide.title} className="max-w-full" />
                  </div>
                  <div className="lg:pl-8 lg:pr-24 pt-6 text-white w-full lg:w-1/3 text-center lg:text-left">
                    <p className="mr-4 mt-4 text-lg">Why Millboard?</p>
                    <h4 className="font-F37-light mt-4 text-3xl lg:text-h4">{slide.title}</h4>
                    <p className="pt-6">{slide.para}</p>
                    <a
                      href="/about-us"
                      className="py-2 mt-6 inline-block link text-white rounded hover:bg-green-light transition duration-300"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Carousel Controls */}
          <div className="absolute bottom-2 left-2 flex items-center space-x-4">
            <button
              className="text-white p-2 rounded-full hover:bg-gray-700"
              onClick={handlePrev}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              className="text-white p-2 rounded-full hover:bg-gray-700"
              onClick={handleNext}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
            {/* Slide Number and Progress Bar */}
            <div className="flex items-center space-x-4">
              <div className="flex space-x-2">
                <span className="text-white">{`0${currentSlide + 1}`}</span>
                <span className="text-white">/</span>
                <span className="text-white">{`0${slides.length}`}</span>
              </div>
              <div className="w-24 bg-gray-600 h-1 relative">
                <div className="bg-green-500 h-1 absolute top-0 left-0 transition-all duration-500" style={{ width: `${((currentSlide + 1) / slides.length) * 100}%` }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IconsRow;
