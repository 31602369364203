import React, { useState } from 'react';

const images = [
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
  "https://millboard.widen.net/content/932af597-179b-4df8-bc37-1d672600550b/web/MDE126A_Enhanced%20Grain%20SB_%20Antique%20Oak_%20Hero.jpg",
];

const InspirationGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (src) => setSelectedImage(src);
  const closeModal = () => setSelectedImage(null);

  return (
    <div className="max-w-screen-lg mx-auto px-4">
      <p className='font-F37-light text-center py-10 text-4xl uppercase'>Inspiration</p>
      
      {/* Gallery */}
      <div className="mb-2 flex gap-2">
        <div className="grid w-1/3 grid-rows-2 gap-2">
          {images.slice(0, 2).map((src, index) => (
            <div key={index} className="object-cover w-full h-full cursor-pointer rounded-md">
              <img
                src={src}
                alt={`Image ${index}`}
                onClick={() => openModal(src)}
              />
            </div>
          ))}
        </div>
        <div className="object-contain w-2/3 h-full cursor-pointer ">
          <img
            src={images[2]}
            alt="Image 2"
            onClick={() => openModal(images[2])}
          />
        </div>
      </div>
      <div className="mb-2 flex gap-2">
        {images.slice(3, 5).map((src, index) => (
          <div key={index} className="object-cover w-1/2 h-full cursor-pointer rounded-md">
            <img
              src={src}
              alt={`Image ${index + 3}`}
              onClick={() => openModal(src)}
            />
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        {images.slice(5, 8).map((src, index) => (
          <div key={index} className="object-cover w-1/3 h-full cursor-pointer rounded-md">
            <img
              src={src}
              alt={`Image ${index + 5}`}
              onClick={() => openModal(src)}
            />
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50" onClick={closeModal}>
          <div className="relative">
            <img src={selectedImage} alt="Selected" className="max-w-full max-h-full rounded-md" />
            <button
              className="absolute top-2 right-2 text-white text-3xl font-bold bg-black rounded-full p-2"
              onClick={closeModal}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InspirationGallery;
