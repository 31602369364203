import React from 'react';

const CladdingContent = () => {
  return (
    <div className='sticky top-0'>
      <div className='container max-w-screen-sm'>
        <h4 className='text-2xl mb-2'>Decking</h4> 
        <h1 className='text-5xl uppercase mb-8'>Enhanced Grain</h1>
        <p className='font-F37-light text font-normal'>
          Moulded from timbers that are selected from oak boards for a textured look and feel, the Enhanced Grain collection's contemporary design accurately recreates the subtle nuances of natural wood. Each of the shades are slip-resistant and showcase their organic patinas beautifully.
        </p>
        <div className='border-t border-white-background mt-10'>
          <div className='flex justify-items-start gap-4 my-6 items-baseline'>
            <span className='font-F37-light text font-normal'>Colour</span>
            <h5 className='text-lg'>Antique Oak</h5>
          </div>
          <div className='flex flex-wrap gap-2'>
            {Array.from({ length: 9 }).map((_, index) => (
              <a href='#' key={index}>
                <img src='/assets/Cladding/22.jpg' alt={`Product ${index + 1}`} className='btn-item special-link' />
              </a>
            ))}
          </div>
          <div className='my-6'>
            <span>Board Width</span>
          </div>
          <div className='mt-2'>
            <a href='#' className='btn-length mr-2'>176mm</a>
            <a href='#' className='btn-length'>126mm</a>
          </div>
          <button className='btn-length mt-6 min-w-full'>add free sample</button>
        </div>
      </div>
    </div>
  );
};

export default CladdingContent;
