import React from 'react'

const Hero = () => {
  return (
    <div className='mx-24 max-w-full'>
      <div className='container px-6 py-16'>
        <div className='px-6 mb-6 grid col-span-4'>
          
          <h1 className='text-center text-5xl font-F37-light tracking-wider '>MILLBOARD SHOWROOMS DISCOVER THE ART OF OUTDOOR LIVING</h1>
        </div>
        <div className=" justify-center grid grid-cols-8 gap-4 ">
          <div className=""></div>
          <div className="text-center col-start-3 col-span-5 ">
                      <p className=" text-center font-F37-light leading-6 tracking-wide">Welcome to the Millboard Showrooms, your gateway to experiencing outdoor living like never before. Our showrooms are more than just a place to display our innovative products; they are an inspiration for your own outdoor utopia.  Step into a world where craftsmanship meets innovation, and where the timeless beauty of wood is enhanced with modern technology.</p>
          </div>
          <div></div>

        </div>

      </div>

    </div>
  )
}

export default Hero
