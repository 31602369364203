import React from 'react'
import CustomerFeedback from '../../components/Others/CustomerFeedback'


const Comment = () => {
  return (
    <div>
      <CustomerFeedback quote="The depth in these boards is so impressive. They look unmistakably natural, even close up." name="David" designation="Homeowner"/>
      {/* Other components and content */}
    </div>
  );
};

export default Comment
