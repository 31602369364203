import React from 'react';
import ItemGalleryLarge from './ItemGalleryLarge';
// import ItemGalleryCarousel from './ItemGalleryCarousel';

const ItemsGallery = () => {
  return (
    <div>
      <ItemGalleryLarge />
      {/* <ItemGalleryCarousel /> */}
      
    </div>
  );
}

export default ItemsGallery;
