import React from 'react'

const Hero = () => {
  return (
    <>
    <div className='py-16 px-6'>
      <h1 className='uppercase mb-6 font-F37-light text-5xl text-center'>MILLBOARD DECKING COLLECTIONS</h1>
      <div className='flex justify-center gap-6'>
        <div className='flex items-center gap-2'>
          <img src='./assets/Decking/collection.svg' alt='collection' className=''/>
          <p > 3 Collections</p>
        </div>
        <div className='flex items-center gap-2'>
          <img src='./assets/Decking/color-palete.svg' alt='collection' className=''/>
          <p > 10 Colours</p>
        </div>
      </div>
    </div>
    <div className='flex gap-2 px-6 h-[34rem]'>
        <img src='./assets/Decking/MDE176H_Enhanced Grain_Ashwood_Lifestyle Daytime Hero.webp' alt="Ashwood Lifestyle" className='w-2/3'/>
        <img src='./assets/Decking/MDE176Y_Enhanced Grain_Ebony Grey_Lifestyle 45 angle detail.png' alt='Ebony Grey' className='w-1/3'/>
    </div>
      
    
    </>
  )
}

export default Hero
