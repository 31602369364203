import React, { useState } from 'react';

const FAQs = () => {
  // State to keep track of the open accordion
  const [openIndex, setOpenIndex] = useState(null);

  // Function to handle accordion toggle
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'How can I reset my password?',
      answer: 'To create an account, find the \'Sign up\' or \'Create account\' button, fill out the registration form with your personal information, and click \'Create account\' or \'Sign up.\' Verify your email address if needed, and then log in to start using the platform.',
    },
    {
      question: 'How do I update my billing information?',
      answer: 'To create an account, find the \'Sign up\' or \'Create account\' button, fill out the registration form with your personal information, and click \'Create account\' or \'Sign up.\' Verify your email address if needed, and then log in to start using the platform.',
    },
    {
      question: 'How can I contact customer support?',
      answer: 'To create an account, find the \'Sign up\' or \'Create account\' button, fill out the registration form with your personal information, and click \'Create account\' or \'Sign up.\' Verify your email address if needed, and then log in to start using the platform.',
    },
    {
      question: 'How do I delete my account?',
      answer: 'To create an account, find the \'Sign up\' or \'Create account\' button, fill out the registration form with your personal information, and click \'Create account\' or \'Sign up.\' Verify your email address if needed, and then log in to start using the platform.',
    }
  ];

  return (
    <section className='py-24 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
      <div className='mb-16'>
        <h2 className='text-4xl font-manrope text-center uppercase font-bold text-gray-900 leading-[3.25rem]'>
          Frequently asked questions
        </h2>
      </div>
      <div>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`accordion border border-solid border-gray-300 p-4 rounded-xl transition duration-500 mb-8 lg:p-4 ${
              openIndex === index ? 'bg-indigo-50 border-indigo-600' : ''
            }`}
          >
            <button
              className='accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 w-full transition duration-500 hover:text-indigo-600'
              onClick={() => handleToggle(index)}
            >
              <h5>{faq.question}</h5>
              <svg
                className={`w-6 h-6 text-gray-900 transition duration-500 ${
                  openIndex === index ? 'hidden' : 'block'
                }`}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 12H18M12 18V6'
                  stroke='currentColor'
                  strokeWidth='1.6'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 text-gray-900 transition duration-500 ${
                  openIndex === index ? 'block' : 'hidden'
                }`}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6 12H18'
                  stroke='currentColor'
                  strokeWidth='1.6'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
            </button>
            <div
              className={`accordion-content w-full overflow-hidden pr-4 transition-max-height duration-500 ease-in-out ${
                openIndex === index ? 'max-h-64' : 'max-h-0'
              }`}
            >
              <p className='text-base text-gray-900 font-normal leading-6'>
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQs;
