import React from 'react';

const data = [
  {
    title: "Weathered Oak",
    description: "Richly textured to mirror the distinctive aesthetic of premium aged oak",
    availability: "3 colours available",
    mainImage: "/assets/Decking/Decking_Weathered Oak_Collections.png",
    images: [
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png",
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png"
    ],
    mainImageAlt: "Decking Weathered Oak Collections",
  },
  {
    title: "Weathered Oak",
    description: "Richly textured to mirror the distinctive aesthetic of premium aged oak",
    availability: "3 colours available",
    mainImage: "/assets/Decking/Decking_Weathered Oak_Collections.png",
    images: [
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png",
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png"
    ],
    mainImageAlt: "Decking Weathered Oak Collections",
  },
  {
    title: "Weathered Oak",
    description: "Richly textured to mirror the distinctive aesthetic of premium aged oak",
    availability: "3 colours available",
    mainImage: "/assets/Decking/Decking_Weathered Oak_Collections.png",
    images: [
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png",
      "/assets/Decking/MDW200R_Weathered Oak_Embered_Cameo 2.png"
    ],
    mainImageAlt: "Decking Weathered Oak Collections",
  }
];

const DeckingList = () => {
  return (
    <div className="container mx-auto p-4 pb-10 flex flex-col gap-10">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-5">
          <div className="text-left lg:w-1/2">
            <img
              src="/assets/Homepage/Millboard Collections.png"
              alt="Millboard Collections"
              className="mt-10 mb-10 h-20"
            />
            <div className="mt-10">
              <h2 className="uppercase mb-4 text-4xl font-F37-light tracking-wide">
                {item.title}
              </h2>
              <p className="font-F37-light text-sm mb-4">
                {item.description}
              </p>
              <p className="uppercase font-F37-light mb-4">
                {item.availability}
              </p>
              <img
                src={item.mainImage}
                alt={item.mainImageAlt}
                className="mb-8 animate-slide-in-from-left"
              />
              <div className="mt-4">
                <a href="#" className="btn-length uppercase">
                  Discover the range
                </a>
              </div>
            </div>
          </div>
          <div className="px-1 lg:w-1/2 flex flex-col gap-2">
            {item.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={image.split('/').pop()}
                className="w-full h-auto"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeckingList;
