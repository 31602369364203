import React from 'react'

const NextProject = () => {
  return (
    <h1>NextProject</h1>

  )
}

export default NextProject
