import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { IconContext } from "react-icons";

const Logos = () => {
  return (
    <IconContext.Provider value={{ className: 'react-icons' }}>
      <div className='flex justify-center items-center space-x-14 mt-2 mb-6'>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className='no-underline'>
          <FaFacebook />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className='no-underline'>
          <RiTwitterXLine />
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className='no-underline'>
          <AiOutlineYoutube />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className='no-underline'>
          <FaInstagram />
        </a>
      </div>
    </IconContext.Provider>
  );
}

export default Logos;
