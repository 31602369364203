import React from 'react'

const ItemSpecification = () => {
  return (
    <div className='py-20 mx-24'>
        <h1 className='mb-12 font-F37-light uppercase text-3xl'>Specifications</h1>
        <div className='grid grid-cols-2 '>
          <div className='flex justify-items-end pb-6 pt-4 mt-4 border-b border-b-white-background space-x-72'>
            <p className='text-xl'>Weight per m2 (kg)</p>
            <span className='text-xl font-F37-light '>17.6kg</span>
          </div>
          <div className='flex justify-items-end pb-6 pt-4 mt-4 border-b border-b-white-background space-x-72'>
            <p className='text-xl'>Weight per m2 (kg)</p>
            <span className='text-xl font-F37-light '>17.6kg</span>
          </div>
          <div className='flex justify-items-end pb-6 pt-4 mt-4 border-b border-b-white-background space-x-72'>
            <p className='text-xl'>Weight per m2 (kg)</p>
            <span className='text-xl font-F37-light '>17.6kg</span>
          </div>
          <div className='flex justify-items-end pb-6 pt-4 mt-4 border-b border-b-white-background space-x-72'>
            <p className='text-xl'>Weight per m2 (kg)</p>
            <span className='text-xl font-F37-light '>17.6kg</span>
          </div>
          <div className='flex justify-items-end pb-6 pt-4 mt-4 border-b border-b-white-background space-x-72'>
            <p className='text-xl'>Weight per m2 (kg)</p>
            <span className='text-xl font-F37-light '>17.6kg</span>
          </div>
        </div>

    </div>
    

  )
}

export default ItemSpecification
