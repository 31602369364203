import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './landingPage/home/HomePage';
import Cladding_Item from '../src/landingPage/Cladding_Item/Cladding_Item'; 
import DeckingCollection from './landingPage/DeckingCollection/DeckingCollection';
import Showrooms from './landingPage/showrooms/Showrooms';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header/>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/decking" element={<Cladding_Item/>} /> {/* Example route */}
        <Route path="/composite-decking-collections" element={<DeckingCollection/>} /> {/* Example route */}
        <Route path="/showrooms" element={<Showrooms/>} /> {/* Example route */}
      </Routes>
    </Router>
    <Footer/>
  </React.StrictMode>
);
