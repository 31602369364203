import React from 'react';
import Loop from './Loop';

const FooterMain = () => {
  return (
    <div className="mx-auto w-full max-w-screen-xl flex">
      <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
        <div>
        <div className='flex-col'>
          <h2 className="mb-4 font-semibold text-primary text-xl">Our Products</h2>
          <ul className="text-gray-500 dark:text-gray-400 font-extrabold mb-5 text-sm">
            Decking
            <li className="text-xs leading-6">
              <a href="#">Millboard Collections</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Enhanced Grain</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Weathered Oak</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Lasta Grip</a>
            </li>
          </ul>

          <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
            Cladding
            <li className="text-xs leading-6">
              <a href="#">Envello</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Board & Batten +</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Décor</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Shadow Line +</a>
            </li>
          </ul>
        </div>

        {/* Our Products Row 2 */}
          <div>
            <h2 className="mb-4 font-semibold text-primary text-xl">Inspiration</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
              
              <li className="text-xs leading-6">
                <a href="#">Blog</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Case Studies</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Gallery</a>
              </li>
            </ul>
          </div>
        </div>
        
        {/* Our Products Column 1 */}


        {/* Discover Millboard */}
        <div className='ml-5'>
          <div >
            <h2 className="mb-4 font-semibold text-primary text-xl">Discover <br/>Millboard</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
              <li className="text-xs leading-6">
                <a href="#">About us</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Careers</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Reviews</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Showrooms</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Sustainability</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Warranties</a>
              </li>
            </ul>
          </div>
            <div>
            <h2 className="mb-4 font-semibold text-primary text-xl pt-20">Resources</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
              
              <li className="text-xs leading-6">
                <a href="#">Downloads</a>
              </li>
              <li className="text-xs leading-6">
                <a href="#">Portal</a>
              </li>
    
            </ul>
          </div>
        </div>

        {/* Start a Project */}
        <div className='ml-5'>
          <h2 className="mb-4 font-semibold text-primary text-xl">Start a project</h2>
          <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
            <li className="text-xs leading-6">
              <a href="#">Find an installer</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Installation Guide</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Order a sample</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Where to buy</a>
            </li>
          </ul>
        </div>

        {/* Customer Service */}
        <div className='ml-4'>
          <h2 className="mb-4 font-semibold text-primary text-xl">Customer <br/>Service</h2>
          <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
            <li className="text-xs leading-6">
              <a href="#">Approved global distributors</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Contact us</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">FAQs</a>
            </li>
          </ul>
          <ul className="text-gray-500 dark:text-gray-400 font-extrabold pb-2 text-sm">
            General Enquiries
            <li className="text-xs leading-6">
              <a href="#">024 7643 9943</a>
            </li>
            <li className="text-xs leading-6">
              <a href="#">Email: <br/>sushantbasnet2027@gmail.com</a>
            </li>
          </ul>
        </div>
        
      </div>
      <Loop />
    </div>
  );
};
export default FooterMain;