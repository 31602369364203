import React from 'react'
import FooterMain from './FooterMain'
import FooterBottom from './FooterBottom'
import TopNavFooter from './TopNavFooter'
import Logos from './Logos'
import PoliciesList from './PoliciesList.jsx'

const Footer = () => {
  return (
    <div className='border-t border-t-white-background'>
      <TopNavFooter />
      <FooterMain />
      <Logos />
      <PoliciesList/>
      <FooterBottom/>

    </div>
  )
}

export default Footer

