import React from 'react';
import { LiaDownloadSolid } from 'react-icons/lia';
import { IconContext } from 'react-icons';

const files = [
  'Enhanced Grain Slip Testing - BS 7976-2 (Slider 96 - Shod foot).pdf',
  'Another File Name.pdf',
  'Yet Another File.pdf',
  'Enhanced Grain Slip Testing - BS 7976-2 (Slider 96 - Shod foot).pdf',
  'Enhanced Grain Slip Testing - BS 7976-2 (Slider 96 - Shod foot).pdf',
  'Enhanced Grain Slip Testing - BS 7976-2 (Slider 96 - Shod foot).pdf',
  // Add more files here as needed
];

const Download = () => {
  return (
    <div className='py-20 mx-24'>
      <h1 className='mb-12 font-F37-light uppercase text-3xl'>Downloads</h1>
      <IconContext.Provider value={{ className: 'download-icons' }}>
        <div className='grid grid-cols-3  gap-y-14'>
          {files.map((fileName, index) => (
            <a
              key={index} // Use index as key (you can use a unique identifier if available)
              href={fileName}
              download
              className='flex items-center text-xl font-F37-light text-primary no-custom-underline hover:text-green'
            >
              <LiaDownloadSolid className='' />
               &nbsp;
              {fileName}
              
            </a>
          ))}
        </div>
      </IconContext.Provider> 
    </div>
  );
};

export default Download;
