import React from 'react'
import Hero from '../../landingPage/showrooms/Hero'
import ImageGallery from './ImageGallery'


const Showrooms = () => {
  return (
    <>
        <Hero/>
        <ImageGallery/>
    
    </>

  )
}

export default Showrooms
