import React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
// import outlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses';

const Loop = () => {
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '--TextField-brandBorderColor': '#e8e7e1',
                            '--TextField-brandBorderHoverColor': '#799512',
                            '--TextField-brandBorderFocusedColor': 'green',
                            '& label.Mui-focused': {
                                color: 'var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },

                MuiInput: {
                    styleOverrides: {
                        root: {
                            '&::before': {
                                borderBottom: '2px solid var(--TextField-brandBorderColor)',
                            },
                            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                            },
                            '&.Mui-focused:after': {
                                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },
            },
        });

    const outerTheme = useTheme();

    return (
        <form className='pt-4 pl-8 -ml-6'>
            <div className='flex-col'>
                <h5>Stay in the loop</h5>
                <ThemeProvider theme={customTheme(outerTheme)}>
                    <TextField
                        id="first-name"
                        label="First Name"
                        size="medium"
                        variant="standard"
                        margin="dense"
                        fullWidth
                        color="primary"
                    />
                    <TextField
                        id="last-name"
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        size="medium"
                        margin="dense"
                    />
                    <TextField
                        id="email"
                        label="Email"
                        size="medium"
                        variant="standard"
                        required
                        fullWidth
                        margin="dense"
                        color="secondary"
                        
                    />
                </ThemeProvider>
                <p className='font-extralight font mt-6'>You can unsubscribe from <br/>communication at any time</p> 
                <button type="submit" className='btn-primary mt-6'fullWidth>Submit</button>
            </div>
        </form>
    );
};

export default Loop;
