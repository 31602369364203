import React from 'react'

const ImageGallery = () => {
    return (
        <div className=" flex container"> {/* Centering the gallery */}
          <div className="w-3/5"> {/* First row with one image */}
            <img src="/assets/Cladding/1.jpg" alt="Cladding Item 1" className="object-cover w-full h-full"/>
          </div>

            <div className="w-1/5">
                <img src="/assets/Cladding/21.jpg" alt="Cladding Item 2" className="object-cover w-full h-full cursor-pointer"/>
            </div>
        </div>    
            
      );
}

export default ImageGallery
