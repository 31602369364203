import React from 'react'
import CustomerFeedback from '../../components/Others/CustomerFeedback'

const Customer = ({quote,name,designation}) => {
  return (
    <>
    <CustomerFeedback quote={"Having previously specified Millboard products on design projects for schools, I knew that I could rely on the range for its durability and anti-slip properties, on top of its natural aesthetic."} name={"Suzie Jewell"} designation={'Landscape Architect at Kew Gardens'}/>
    
    </>
  

  )
}

export default Customer
