import React from 'react'

//Component Added
import Hero from './Hero';
import MillboardCollection from './MillboardCollection';
import IconsRow from './IconsRow';
import WhyMillboard from './WhyMillboard';
import Customer from './Customer';
import ShadePic from './ShadePic';
import MillboardWoodDot from './MillboardWoodDot';
// import Design from '../../Design';

//import headers and footer
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import '../../index.css'


const HomePage = () => {
  return (
    <>
        <Hero/>
        <MillboardCollection/>
        <ShadePic/>
        <IconsRow/>
        <WhyMillboard/>      
        <Customer/>
        <MillboardWoodDot/>
    </>


  )
}

export default HomePage
