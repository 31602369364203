import React, { useState, useEffect } from 'react';

const CustomCarousel = () => {
  <></>

  // const slides = [
  //   {
  //     title: "Explore New Horizons",
  //     para: "Discover new places and experiences with our exclusive travel deals.",
  //     image: "assets/Homepage/Travel1.webp",
  //   },
  //   {
  //     title: "Experience the Luxury",
  //     para: "Indulge in luxury with our premium collection of high-end products.",
  //     image: "assets/Homepage/Luxury1.webp",
  //   },
  //   {
  //     title: "Adventure Awaits",
  //     para: "Embark on thrilling adventures with our adventure packages and gear.",
  //     image: "assets/Homepage/Adventure1.webp",
  //   }
  // ];

  // const [currentSlide, setCurrentSlide] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     handleNext();
  //   }, 5000); // auto-slide every 5 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  // const handlePrev = () => {
  //   setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  // };

  // const handleNext = () => {
  //   setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  // };

  // return (
  //   <div className="relative w-full h-80">
  //     <div className="relative h-full overflow-hidden">
  //       {slides.map((slide, index) => (
  //         <div
  //           key={index}
  //           className={`absolute w-full h-full transition-opacity duration-1000 ${
  //             index === currentSlide ? 'opacity-100' : 'opacity-0'
  //           }`}
  //         >
  //           <img src={slide.image} alt={slide.title} className="w-full h-full object-cover" />
  //           <div className="absolute inset-0 flex items-center justify-center text-center bg-black bg-opacity-50 p-6">
  //             <div className="text-white">
  //               <h2 className="text-2xl font-bold">{slide.title}</h2>
  //               <p className="mt-4">{slide.para}</p>
  //               <a
  //                 href="/learn-more"
  //                 className="mt-4 inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
  //               >
  //                 Learn More
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //     {/* Carousel Controls */}
  //     <div className="absolute inset-0 flex items-center justify-between px-4">
  //       <button
  //         className="text-white p-2 bg-gray-800 rounded-full hover:bg-gray-700"
  //         onClick={handlePrev}
  //       >
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           className="w-6 h-6"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth={2}
  //             d="M15 19l-7-7 7-7"
  //           />
  //         </svg>
  //       </button>
  //       <button
  //         className="text-white p-2 bg-gray-800 rounded-full hover:bg-gray-700"
  //         onClick={handleNext}
  //       >
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //           className="w-6 h-6"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth={2}
  //             d="M9 5l7 7-7 7"
  //           />
  //         </svg>
  //       </button>
  //     </div>
  //     {/* Indicator Dots */}
  //     <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
  //       {slides.map((_, index) => (
  //         <button
  //           key={index}
  //           className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-500'}`}
  //           onClick={() => setCurrentSlide(index)}
  //         />
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default CustomCarousel;
