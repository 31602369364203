import React from 'react';

const ShadePic = () => {
  const gradientStyle = {
    background: `linear-gradient(90deg, rgb(65, 64, 66) 50%, rgba(65, 64, 66, 0.4) 100%), url("https://web.archive.org/web/20231219102617/https://s3.eu-west-1.amazonaws.com/millboard-usa/home/greyscale/crafted-image.jpg")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '50vh', // Use viewport height for a responsive design
    width: '100%',
  };

  return (
    <div>
      <span class="inline-block h-1 w-1/3 rounded bg-white "></span>
    
    <div className='relative flex items-center justify-center text-center text-white ' style={gradientStyle}>
    
      <div className='bg-black bg-opacity-50 py-36 rounded-md max-w-lg'>
      
        <div className='flex'>
          
          <h4 className='text-5xl  font-F37-light -ml-11  mb-4'>Crafted to Perfection</h4>
          
          

        </div>
        
        <p className='text-base font-F37-ligh font-thin mt-9 max-w-[29.375rem] text-left'>
          From the pouring of the fiber-reinforced resin-mineral filler to the hand-coloring of every board,
          our focus is always on achieving the finest quality of finish. Our desire for perfection permeates
          every square inch, and that’s why so many of our processes are carried out by hand. We don’t settle
          for less than authentic wood grain and tones.
        </p>
      </div>
    </div>
    </div>
  );
};

export default ShadePic;
