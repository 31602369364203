import React from 'react'

const TopNavFooter = () => {
  return (
    <>
      <img src="../assets/Logo.svg" alt="logo" className="flexM mx-auto py-16 px-14" />
    </>

  )
}

export default TopNavFooter
