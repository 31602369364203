import React from 'react'
import Hero from './Hero'
import DeckingList from './DeckingList' 
import BenifitCarousel from './BenifitCarousel'
import Comment from './Comment'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const DeckingCollection = () => {
  return (
    <div>
      <Hero/>
      <DeckingList/>
      <BenifitCarousel/>
      <Comment/>
    </div>
  )
}

export default DeckingCollection
