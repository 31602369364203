import React from 'react'
import TopNav from './TopNav'
import MegaNav from './MegaNav'

const Header = () => {
  return (
    <>
        <TopNav/>
        <MegaNav/>
    
    </>

  )
}

export default Header

