import React from 'react';

const ItemsGalleryLarge = () => {
  return (
    <div className="lg:inline-block space-y-2 max-w-screen-2xl mx-auto"> {/* Centering the gallery */}
      <div className="w-full"> {/* First row with one image */}
        <img src="/assets/Cladding/1.jpg" alt="Cladding Item 1" className="object-cover w-full h-full"/>
      </div>
      <div className="flex gap-x-2"> {/* Second row with two images in flex container */}
        <div className="w-1/2">
            <img src="/assets/Cladding/21.jpg" alt="Cladding Item 2" className="object-cover w-full h-full cursor-pointer"/>
        </div>
        <div className="w-1/2">
            <img src="/assets/Cladding/22.jpg" alt="Cladding Item 23" className="object-cover w-full h-full cursor-pointer"/>
        </div>
      </div>
      <div className="w-full"> {/* Third row with one image */}
        <img src="/assets/Cladding/3.png" alt="Cladding Item 3" className="object-cover w-full h-full"/>
      </div>
    </div>
  );
};

export default ItemsGalleryLarge;
