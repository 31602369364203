import React, { useState } from 'react';
import { BsGlobe } from "react-icons/bs";

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button
        id="dropdownHoverButton"
        className="text-primary   hover:opacity-80 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
      >
        <BsGlobe />&nbsp;ENG
       
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownHover"
          className="absolute z-10 text-primary divide-y divide-gray-100 rounded-lg shadow w-44 "
        >
          <ul className="py-2 text-sm text-primary dark:text-gray-200" aria-labelledby="dropdownHoverButton">
          <li>
              <a href="#" className="block px-4 py-2 hover:bg-white-background">
                ENG
              </a>
            </li>
            <li>
              <a href="#" className="block px-4 py-2 hover:bg-white-background">
                ESPN
              </a>
            </li>
            <li>
              <a href="#" className="block px-4 py-2 hover:bg-white-background">
                IE
              </a>
            </li>
            <li>
              <a href="#" className="block px-4 py-2 hover:bg-white-background">
                DE
              </a>
            </li>
          
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
