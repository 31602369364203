import React from 'react'
import ItemsGallery from './ItemsGallery'
import CladdingContent from './CladdingContent'



const CladdingMain = () => {
  return (
    <div className='flex  max-2xl: gap-x-36 py-8 mx-4 md:mx-8 lg:mx-16 xl:mx-24'>
      <ItemsGallery/>
      <CladdingContent />
    
    </div>

  )
}

export default CladdingMain
