import React from 'react'
// import { BsGlobe } from "react-icons/bs";
// import { IoIosArrowDown } from "react-icons/io";
import DropdownMenu from './DropDownMenu';
import { IoIosCall } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { IconContext } from "react-icons";
import { IoBagOutline } from "react-icons/io5";


const TopNav = () => {
  // const [isOpen,setIsOpen]=useState(false)
  return (
    <div className='flex px-12 pt-6 pb-7 border-b-white-background bg-white-nav justify-between'>
      
      <div className='gap-2'>
      <DropdownMenu />
      <ul className=' gap-2 inline-flex text-lg items-center'><IoIosCall /> <a href="tel:02074824661">020 7482 4661</a></ul>

      </div>
      <a href='/'><img src='assets/Logo.svg' alt='logo' className='h-10 w-56'  /> </a>
      <div className='flex gap-2'>
        
      <IconContext.Provider value={{ className: 'react-icons ' }}>
      <a href="https://www.instagram.com/living_outdoors.co.uk/" target="_blank" rel="noopener noreferrer" className='flex gap-1'> <FaInstagram /><span>Instagram</span></a>
      </IconContext.Provider>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <IoBagOutline /><span>Basket</span>
      </IconContext.Provider>
      
        
      </div>
    
    </div>

    // <div className='bg-white-nav px-12 pt-6 pb-8'>
    //   <div className='cursor-pointer flex justify-center items-center space-x-1'>
    //     <button className='flex space-x-2 justify-center items-center' onClick={()=>setIsOpen((prev)=> !prev)} >
    //       <BsGlobe />
    //        ENG
    //        {!isOpen(
    //         <IoIosArrowDown />
    //        )}

    //     </button>
    //     <BsGlobe /><span>ENG</span><IoIosArrowDown />
    //     <div className='rounded cursor-pointer hidden'>
    //       <ul>
    //         <li>ENG</li>
    //         <li>EsPN</li>
    //         <li>ChN</li>
    //       </ul>

    //     </div>

    //   </div>
      

    // </div>

  )
}

export default TopNav
