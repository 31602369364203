import React from 'react';
import Typewriter from './Typewriter';
import HeroBanner from './HeroBanner/HeroBanner'


const Hero = () => {
  return (
    <div className="p-5 text-center max-w-full">
      <div className='py-16 px-9 font-F37-light'>
        <h1 className='uppercase text-5xl font-extralight mb-6'>
          LIVE. LIFE. <span className="text-green"><Typewriter words={["OUTSIDE.", "AWESOME.", "OUTDOORS."]} /></span>
        </h1>
        <p className='text-base'>The world’s finest hand-moulded</p>
        <p className="font-bold">composite decking & composite cladding</p>
      </div>
      <div>
      {/* <HeroBanner
    // imageUrl="https://www.millboard.com/sites/default/files/styles/full_bleed_image_1920x1080_/public/2024-07/IMGL2200%20%282%29.jpg?h=a115625b&itok=tqhJC5Fe"
    // altText="Outdoor decking area with seating"
    // className="custom-class"
/> */}
      <HeroBanner
        videoSrc="assets/Homepage/MDE126A_Enhanced Grain SB_Antique Oak_Herringbone_Video.mp4"
        altText="Video of outdoor decking area"
        className="custom-class"
    />


        
      </div>
      <div className='flex  justify-center py-16 mb-6'>
        <h4 className='font-F37-light text-left text-2xl w-1/2 h-32'>
          <span className='font-extrabold '>Millboard enhances</span> outdoor spaces with enduring distinction. Hand-moulded from the finest oak, it realistically mimics natural timber but is engineered to offer much more.
        </h4>
        
        


      </div>
    </div>
  );
};

export default Hero;
