import React from 'react';

const MillboardCollection = () => {
  const handleDeckingClick = () => {
    // Redirect to the decking page
    window.location.href = '/decking';
  };

  const handleCladdingClick = () => {
    // Redirect to the cladding page
    window.location.href = '/decking'; // Assuming a similar route exists
  };

  return (
    <div className='px-6'>
      <div className="by-millboard">
        <img src="assets/Homepage/Millboard Collections.png" alt="Millboard Collection" />
      </div>

      <div
        className="container min-h-screen max-w-full flexM background-cover"
        style={{ backgroundImage: 'url(assets/Homepage/millboard_oak.webp)' }}
      >
        <button className="btn-white" onClick={handleDeckingClick}>
          View Decking Collections
        </button>
      </div>

      <div className="by-millboard">
        <img src="assets/Homepage/Envello by Millboard.png" alt="Millboard Envello" />
      </div>

      <div
        className="container min-h-screen max-w-full flexM background-cover"
        style={{ backgroundImage: 'url(assets/Homepage/MCBF360A_Board&Batten.webp)' }}
      >
        <button className="btn-white" onClick={handleCladdingClick}>
          View Cladding Collections
        </button>
      </div>
    </div>
  );
};

export default MillboardCollection;
