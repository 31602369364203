import React from 'react';
import { IoLocationSharp } from "react-icons/io5";

const FooterBottom = () => {
  return (
    <>
      <div>
        <hr className="bg-white-nav border-none" />
        <section className="bg-white-nav text-center font-normal items-center justify-center py-3">
          <span className="flex items-center justify-center">
            <IoLocationSharp className="mr-1" /> United Kingdom |&nbsp;
          &copy; 2024 The Living Outdoors Ltd - All rights reserved</span>
        </section>
      </div>
    </>
  );
}

export default FooterBottom;
