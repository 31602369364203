import React from 'react'

const MillboardWoodDot = () => {
  return (
    <h1>MillboardWoodDot</h1>

  )
}

export default MillboardWoodDot
