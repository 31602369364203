import React from 'react';

const features = [
  {
    imgSrc: "/assets/Cladding/features/Hand moulded and coloured.png",
    title: "Hand moulded & coloured",
    description: "Skillfully hand-crafted to ensure a high quality, premium product"
  },
  {
    imgSrc: "/assets/Cladding/features/Wood grain.png",
    title: "Natural wood look",
    description: "Moulded from selected oak for an organic, realistic wood-grain appearance"
  },
  {
    imgSrc: "/assets/Cladding/features/Wood Free.png",
    title: "Wood free",
    description: "Wood free composition prevents rot, warping and insect infestation"
  },
  {
    imgSrc: "/assets/Cladding/features/Lost Head Fixing.png",
    title: "‘Lost head’ fixing",
    description: "Durafix fixings are virtually hidden beneath the unique Lastane surface"
  },
  {
    imgSrc: "/assets/Cladding/features/Soft Touch.png",
    title: "Soft-touch surface",
    description: "Unique Lastane layer means no splinters on the surface"
  },
  {
    imgSrc: "/assets/Cladding/features/Weather resistant.png",
    title: "Impact & weather resistant",
    description: "Elastomeric surface ensures increased resilience against natural weathering"
  },
  {
    imgSrc: "/assets/Cladding/features/Slip Free.png",
    title: "High slip-resistance",
    description: "Lower slip potential than wood due to the pliable Lastane layer"
  },
  {
    imgSrc: "/assets/Cladding/features/Made in GB.png",
    title: "UK made, UK backed",
    description: "Designed and manufactured in the UK, tested independently globally"
  }
];

const CladdingFeatures = () => {
  return (
    <div className='pb-20 pt-[5.5rem]'>
      <div className='py-8 mx-4 md:mx-8 lg:mx-16 xl:mx-24 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        {features.map((feature, index) => (
          <div key={index} className='pt-8'>
            <img src={feature.imgSrc} alt={feature.title} className='size-8' />
            <h5 className='my-2 font-F37-light text-lg md:text-xl leading-6'>{feature.title}</h5>
            <p className='font-F37-light text-sm leading-6'>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CladdingFeatures;
