import React from 'react'

const PoliciesList = () => {
  return (
    <div >
      <ul className='flexM my-2 gap-x-6 '>
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Terms & Conditions</a>
        </li>
        <li>
          <a href="#">Cookies </a>
        </li>
        <li>
          <a href="#">Modern Slavery Statement </a>
        </li>
      </ul>
    </div>
  )
}

export default PoliciesList

