import React from 'react'

//Items Added
import CladdingMain from './CladdingMain';
import CladdingFeatures from './CladdingFeatures';
import InspirationGallery from './InspirationGallery';
import ItemSpecification from './ItemSpecification';
import Download from './Download';
import FAQs from './FAQs';
import NextProject from './NextProject';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';


const Cladding_Item = () => {
  return (
    <>  
        
        <CladdingMain/>
        <CladdingFeatures/>
        <InspirationGallery/>
        <ItemSpecification/>
        <hr className='border-t border-t-white-background '></hr>
        <Download/>
        <hr className='border-t border-t-white-background '></hr>
        <FAQs/>
        <NextProject/>
        
    
    </>

  )
}

export default Cladding_Item
